import React from "react";
import "./OtherPages.scss";
import home1 from "../../Assets/home1.jpg";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const OtherPages = ({ featuredImage, pageName, pageHeader, pageContent }) => {
  return (
    <div>
      <img src={home1} style={{ width: "100%", maxHeight: "300px" }} />
      <div
        style={{ background: "rgb(228 228 1)" }}
        className="content__padding"
      >
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>{pageName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content__padding">
        <h1>{pageHeader}</h1>
        {pageContent}
      </div>
    </div>
  );
};

export default OtherPages;
