import React from "react";
import NavContainer from "../../Containers/NavContainer/NavContainer";
import FeaturedNav from "../../Containers/FeaturedNav/FeaturedNav";
import CustomCarousel from "../../Containers/Carousel/CustomCarousel";
import Featured from "../../Containers/Featured/Featured";
import Projects from "../../Containers/Projects/Projects";
import Testimonials from "../../Containers/Testimonials/Testimonials";
import Footer from "../../Containers/Footer/Footer";
import OtherPages from "../../Containers/OtherPages/OtherPages";

const OtherPageTemplate = ({
  featuredImage,
  pageName,
  pageHeader,
  pageContent,
}) => {
  return (
    <>
      <FeaturedNav />
      <NavContainer />
      <OtherPages
        featuredImage={featuredImage}
        pageName={pageName}
        pageHeader={pageHeader}
        pageContent={pageContent}
      />
      <Footer />
    </>
  );
};

export default OtherPageTemplate;
