import React from "react";
import "./Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <div>
      <div class="pt-5 pb-5 footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-xs-12 about-company">
              <h2>Helping Human Infratech Pvt Ltd</h2>
              <p class="pr-5 text-white-50">
                Welcome to the pinnacle of real estate sophistication – Helping
                Human. Embark on a journey where aspirations meet an unwavering
                commitment to excellence, as we redefine the very fabric of the
                real estate experience.{" "}
              </p>
              <p>
                <FacebookIcon style={{ margin: "10px" }} />
                <TwitterIcon style={{ margin: "10px" }} />
                <InstagramIcon style={{ margin: "10px" }} />
                <YouTubeIcon style={{ margin: "10px" }} />
              </p>
            </div>
            <div class="col-lg-3 col-xs-12 links">
              <h4 class="mt-lg-0 mt-sm-3">Links</h4>
              <ul class="m-0 p-0">
                <li>
                  - <a href="/about-us">About Us</a>
                </li>
                <li>
                  - <a href="/ongoing-projects">Our Projects</a>
                </li>
                <li>
                  - <a href="/our-values">Our Values</a>
                </li>
                <li>
                  - <a href="/mission-vision">Mission and Vision</a>
                </li>
                <li>
                  - <a href="/career">Career</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-xs-12 location">
              <h4 class="mt-lg-0 mt-sm-4">Location</h4>
              <p>Patna</p>
              <p class="mb-0">
                <i class="fa fa-phone mr-3"></i>(+91) 800-2880-404
              </p>
              <p>
                <a
                  href="mailto:info@thehelpinghuman.com"
                  style={{ textDecoration: "none" }}
                >
                  <i class="fa fa-envelope-o mr-3"></i>info@thehelpinghuman.com
                </a>
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col copyright">
              <p class="">
                <small class="text-white-50">
                  © 2023. All Rights Reserved.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
