import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import helping_human from "../../Assets/helping_human.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const FeaturedNav = () => {
  return (
    <Navbar
      expand="lg"
      className="bg-body-primary"
      style={{ marginTop: "60px" }}
    >
      <Container>
        <div
          style={{
            padding: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={helping_human}
            alt="helping human"
            style={{ width: "160px" }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "30px",
              padding: "12px",
              height: "60px",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PhoneInTalkIcon style={{ color: "green" }} />
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ fontWeight: "700" }}>+91-8002880404</div>
            <a
              href="mailto:info@thehelpinghuman.com"
              style={{ textDecoration: "auto", color: "darkgray" }}
            >
              info@thehelpinghuman.com
            </a>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "30px",
              padding: "12px",
              height: "60px",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LocationOnIcon style={{ color: "blue" }} />
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ fontWeight: "700" }}>
              Helping Human Infratech Pvt. Ltd.
            </div>
            <div style={{ textDecoration: "auto", color: "darkgray" }}>
              221, Nafis Villa, Patliputra Colony, Patna
            </div>
          </div>
        </div>
        <div>
          <FacebookIcon style={{ color: "blue", margin: "10px" }} />
          <TwitterIcon style={{ color: "blue", margin: "10px" }} />
          <InstagramIcon style={{ color: "red", margin: "10px" }} />
          <YouTubeIcon style={{ color: "red", margin: "10px" }} />
        </div>
      </Container>
    </Navbar>
  );
};

export default FeaturedNav;
