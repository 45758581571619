import React from "react";

import TestimonialStrip from "./TestimonialStrip";

const Testimonials = () => {
  const testimonialDetails = [
    {
      quote: "It is lowest priced land in 22KM range of Patna",
      writer: "Anonymous ",
      source: "helpinghuman.com",
      writerImg:
        "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",
    },
    {
      quote: "Got documents processed hassle-free and comfortably",
      writer: "Anonymous ",
      source: "helpinghuman.com",
      writerImg:
        "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",
    },
    {
      quote: "Here I got the choice of Cheap to Premium Plots to select from.",
      writer: "Anonymous ",
      source: "helpinghuman.com",
      writerImg:
        "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",
    },
  ];

  return (
    <div
      style={{
        background: "black",
        minHeight: "300px",
        padding: "100px 0",
      }}
      id="Testimonials"
    >
      <h3
        style={{
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
          fontSize: "34px",
        }}
      >
        <strong>TES</strong>TIMONIALS
      </h3>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {testimonialDetails.map((val) => (
          <TestimonialStrip {...val} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
