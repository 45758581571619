import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const NavContainer = () => {
  return (
    <Navbar expand="lg" bg="dark" data-bs-theme="dark" fixed="top">
      <Container>
        <Navbar.Brand href="/">Helping Human</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About Us" id="basic-nav-dropdown">
              <NavDropdown.Item href="/our-values">Ore Values</NavDropdown.Item>
              <NavDropdown.Item href="mission-vision">
                Mission & Vision
              </NavDropdown.Item>
              <NavDropdown.Item href="#Testimonials">
                Testimonials
              </NavDropdown.Item>
              <NavDropdown.Item href="/growth-in-bihar">
                Growth in Bihar
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Our Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="/complete-projects">
                Complete Projects
              </NavDropdown.Item>
              <NavDropdown.Item href="/ongoing-projects">
                Ongoing Projects
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/career">Career</Nav.Link>
            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
            <Nav.Link href="/blog">blog</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavContainer;
