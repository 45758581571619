import React, { useState } from "react";
import HomePageTemplate from "./PageTemplates/HomePageTemplate/HomePageTemplate";
import OtherPageTemplate from "./PageTemplates/OtherPageTemplate/OtherPageTemplate";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";
import key_features_aerocity from "./Assets/key_features_aerocity.png";
import aero_city_media from "./Assets/aero_city_media.png";
import Projects from "./Containers/Projects/Projects";

function App() {
  const [key, setKey] = useState("aminities");
  const pages = [
    {
      uri: "about-us",
      featuredImage: "Test",
      pageName: "About Us",
      pageHeader: "About Us",
      pageContent: (
        <>
          <p>
            Helping Human Infratech Pvt Ltd is all about making it easier for
            people to buy land and homes without breaking the bank. We focus on
            providing affordable options for townships and land. What makes us
            stand out is our dedication to simplifying the complicated legal
            stuff that usually comes with buying property. We want the whole
            process to be simple and stress-free for individuals.
          </p>
          <p>
            Our goal is to make sure more people can own a home by removing
            barriers that might stop them. We do this by making legal steps
            simpler, aiming to help a wide range of people become homeowners and
            believes that buying property should be straightforward and open to
            everyone.
          </p>
          <p>
            By making housing more affordable, we not only help individuals but
            also build lively communities. Our creative ways of doing things
            show that we care about society, striving to give more people the
            chance to own property. Overall, Helping Human Infratech is all
            about making it easy and affordable for individuals to own land and
            be part of thriving communities
          </p>
        </>
      ),
    },
    {
      uri: "our-values",
      featuredImage: "Our Values",
      pageName: "Our Values",
      pageHeader: "Our Values",
      pageContent: (
        <>
          <p>
            At <strong>Helping Human Infra. Pvt. Ltd.</strong>, our foundation
            is built upon a set of core values that guide our actions,
            decisions, and interactions. These values reflect our commitment to
            excellence, integrity, and the betterment of the communities we
            serve.
          </p>
          <h5>Innovation</h5>
          <p>
            We embrace innovation as the heartbeat of our organization. We
            continually seek creative and sustainable solutions to redefine the
            real estate landscape. Our commitment to innovation drives us to
            explore new technologies, design concepts, and construction
            methodologies that elevate our projects to new heights.
          </p>
          <h5>Integrity</h5>
          <p>
            Integrity is the cornerstone of our relationships—with our clients,
            partners, and the communities we operate in. We conduct our business
            with the highest ethical standards, transparency, and
            accountability. Building trust is not just a goal; it's an integral
            part of who we are.
          </p>
          <h5>Customer-Centric Approach</h5>
          <p>
            Our customers are at the center of everything we do. We are
            dedicated to understanding their needs, exceeding their
            expectations, and delivering real estate solutions that enhance
            their quality of life. Listening, adapting, and evolving with our
            customers ensure that we remain their preferred partner in their
            real estate journey.
          </p>
          <h5>Sustainability</h5>
          <p>
            As responsible stewards of the environment, we are committed to
            sustainable development. Our projects are designed with a focus on
            environmental conservation, energy efficiency, and the well-being of
            the communities around us. We believe in leaving a positive impact
            on the planet for future generations.
          </p>
          <h5>Community Engagement</h5>
          <p>
            We recognize the importance of community in shaping the success of
            our projects. Through community engagement initiatives, we aim to
            foster positive relationships, contribute to local development, and
            create spaces that bring people together. Our goal is not just to
            build structures but to strengthen the fabric of the communities we
            are a part of.
          </p>
          <h5>Employee Empowerment</h5>
          <p>
            Our team is our greatest asset, and we invest in their growth and
            well-being. By fostering a collaborative and inclusive work culture,
            we empower our employees to bring their best selves to their roles.
            We believe that a motivated and engaged team is key to delivering
            exceptional results.
          </p>
          <div style={{ fontSize: "26px" }}>
            Join Us in Building a Better Tomorrow
          </div>
          <p>
            As we navigate the dynamic landscape of real estate, these values
            serve as our compass, guiding us towards a future where Helping
            Human Infratech Pvt. Ltd. continues to stand as a symbol of trust,
            innovation, and positive impact.
          </p>
          <p>
            For more information on how we incorporate these values into our
            projects or to explore potential collaborations{" "}
            <a href="tel:8002880404">Contact Us</a>
          </p>
        </>
      ),
    },
    {
      uri: "career",
      featuredImage: "Career",
      pageName: "Career",
      pageHeader: "Career",
      pageContent: (
        <>
          <p>
            Empower Lives with Helping Human: A Journey of Impactful Careers
          </p>
          <p>
            Are you ready to make a difference and shape a meaningful career at
            Helping Human? Join our compassionate team and become an integral
            part of an organization committed to transforming lives and creating
            positive impact in the world.
          </p>
          <p>
            Whether you bring a wealth of experience or are at the beginning of
            your career journey, Helping Human invites you to explore diverse
            opportunities within our family. We are dedicated to nurturing
            talent, empowering individuals to reach their full potential, and
            making a lasting difference in the lives of those we serve.
          </p>
          <p>
            Discover a range of enriching career paths that span various
            sectors, allowing you to align your skills and passions with our
            mission. From community outreach to program management, every role
            at Helping Human contributes to our shared goal of making the world
            a better place.
          </p>
          <p>
            We understand that a fulfilling career goes beyond a job; it's a
            journey of growth, learning, and making a positive impact. At
            Helping Human, we provide a supportive environment, ongoing
            training, and a culture that values empathy, integrity, and
            collaboration.
          </p>
          <p>
            Join us on a journey where your work truly matters. Elevate your
            career, embrace new challenges, and be a part of a team that is
            dedicated to helping humanity thrive. Your impactful career with
            Helping Human begins here.
          </p>
        </>
      ),
    },
    {
      uri: "growth-in-bihar",
      featuredImage: "Growth in Bihar",
      pageName: "Growth in Bihar",
      pageHeader: "Growth in Bihar",
      pageContent: (
        <>
          <h5>Driving Sustainable Growth in Bihar</h5>
          <p>
            At Helping Human Infra. Pvt. Ltd., we are proud to be a part of
            Bihar's transformative journey towards sustainable development.
            Bihar, with its rich cultural heritage and untapped potential, is
            experiencing a remarkable period of growth and progress. As a
            responsible contributor to the state's development, we are committed
            to playing a pivotal role in shaping a brighter future for Bihar.
          </p>
          <p>
            Embracing Bihar’s potential. Bihar is more than a geographical
            location, it’s a; and of opportunities and possibilities. With a
            focus on inclusive development, our projects are strategically
            positioned to harness the economic social and cultural potential
            that Bihar has to offer. By aligning our vision with state’s growth
            trajectory, we aim to contribute to the prosperity of the region.
          </p>
          <h5>Embracing Bihar's Potential</h5>
          <p>
            Bihar is more than a geographical location; it is a land of
            opportunities and possibilities. With a focus on inclusive
            development, our projects are strategically positioned to harness
            the economic, social, and cultural potential that Bihar has to
            offer. By aligning our vision with the state's growth trajectory, we
            aim to contribute to the prosperity of the region.
          </p>
          <h5>Job Creation and Economic Empowerment</h5>
          <p>
            We understand the vital role that employment plays in fostering
            growth. Through our projects, we strive to create job opportunities
            that empower local communities. By promoting skill development and
            providing employment avenues, we contribute to Bihar's economic
            empowerment, fostering a climate of sustainable growth and
            prosperity.
          </p>
          <h5>Cultural Preservation and Community Integration</h5>
          <p>
            Bihar's cultural heritage is a source of pride and inspiration. In
            our endeavors, we are committed to preserving and celebrating the
            cultural richness of the region. Our projects are designed with a
            deep respect for local traditions, fostering a sense of belonging
            and community integration. We believe that sustainable growth is not
            only about physical infrastructure but also about preserving the
            soul of the community.
          </p>
          <h5>Investing in Education and Skill Development</h5>
          <p>
            Education is the cornerstone of progress. Recognizing the importance
            of education and skill development, we invest in initiatives that
            promote learning and empowerment. By supporting educational
            institutions and skill-building programs, we contribute to the
            creation of a knowledgeable and skilled workforce that fuels Bihar's
            growth engine.
          </p>
          <h5>Sustainable and Eco-Friendly Practices</h5>
          <p>
            Environmental sustainability is a key consideration in our projects.
            We are committed to adopting eco-friendly practices that minimize
            our ecological footprint. From green building designs to
            energy-efficient solutions, our focus on sustainability aligns with
            Bihar's commitment to a greener and healthier future.
          </p>
          <h5>Join Us in Bihar's Growth Story</h5>
          <p>
            As Bihar continues its journey towards becoming a hub of innovation,
            development, and prosperity, Helping Human Infra. Pvt. Ltd. is
            honored to be an active participant in this transformative
            narrative. We invite you to join us in shaping the future of Bihar,
            where growth is sustainable, inclusive, and leaves a positive impact
            on generations to come. For more information on our projects in
            Bihar or to explore collaboration opportunities
          </p>
          <p>
            <strong>Contact Us @ 8002880404, 8002040404</strong>
          </p>
          <hr />
          <p>
            यह प्लाट अगले 5 साल में 5 गुना से ज़्यादा का return दे सकता है
            <br />
            Patna से मात्र 20 minute की दूरी पर |
          </p>
          <p>
            *आम आदमी आवास योजना के तहत* बहुत ही काम दाम में अपना ज़मीन ख़रीदे,
            मात्र 3 लाख 90 हज़ार में| लोकेशन: मनसा चक, भोपत चक (नया गांव )
          </p>
          <h5>विशेषताएं :</h5>
          <ul style={{ listStyle: "none" }}>
            <li>✅Patna से मात्र 20 minute की दूरी पर </li>
            <li>✅ JP SETU Golambar से मात्र 15 minute </li>
            <li>✅ MARINE DRIVE से 15 minute </li>
            <li>✅ NH -19 से मात्र 2 km दूरी पर </li>
          </ul>
          <p>
            यह प्लाट अगले 5 साल में 5 गुना से ज़्यादा का return दे सकता है |{" "}
            <br />{" "}
            <strong>
              NOTE : आप अपना ज़मीन EMI की आसान किश्तों पे भी ले सकते हैं, PLOT की
              बुकिंग मात्र 20 हज़ार से भी कर सकते हैं |
            </strong>
            <br /> जल्द कॉल करे, OFFER सीमित समय के लिए है |{" "}
            <strong>CALL: 7480808072</strong>
          </p>
        </>
      ),
    },
    {
      uri: "mission-vision",
      featuredImage: "Our Mission and Vision",
      pageName: "Our Mission and Vision",
      pageHeader: "Our Mission and Vision",
      pageContent: (
        <>
          <p>
            Welcome to <strong>Helping Human Infratech Pvt. Ltd.</strong>, your
            trusted partner in real estate, specializing in plots in and around
            Patna, Bihar. Our mission and vision are the cornerstones that guide
            our journey, shaping our commitment to excellence and the creation
            of opportunities for our valued clients.
          </p>
          <h5>Mission Statement</h5>
          <p>
            <i>
              At <strong>Helping Human Infratech Pvt. Ltd.</strong>, our mission
              is to empower individuals, families, and businesses by providing
              them with a foundation for their dreams. We are dedicated to
              offering quality plots that not only meet the diverse needs of our
              clients but also contribute to the sustainable development of the
              communities we serve.
            </i>
          </p>
          <h5>Vision Statement</h5>
          <p>
            <i>
              Our vision is to be a beacon of innovation and integrity in the
              real estate industry. We aim to redefine the landscape of Patna by
              developing well-planned and environmentally conscious communities.
              Through our commitment to transparency, customer satisfaction, and
              ethical business practices, we envision Helping Human Infratech
              Pvt. Ltd. as the go-to real estate partner for those seeking a
              brighter and more prosperous future.
            </i>
          </p>
          <h5>Guiding Principles</h5>
          <ol>
            <li>
              Customer-Centric Approach:
              <ul>
                <li>
                  We prioritize our clients' needs, ensuring that every
                  interaction is centred on understanding and fulfilling their
                  unique requirements.
                </li>
              </ul>
            </li>
            <li>
              Integrity and Transparency:
              <ul>
                <li>
                  We conduct our business with the highest level of integrity
                  and transparency, building trust and fostering long-term
                  relationships with our clients, partners, and stakeholders.
                </li>
              </ul>
            </li>
            <li>
              Innovation in Development:
              <ul>
                <li>
                  Embracing innovation, we strive to set new benchmarks in real
                  estate development. Our projects incorporate the latest
                  technologies and sustainable practices for a better and more
                  efficient living environment.
                </li>
              </ul>
            </li>
            <li>
              Community Impact:
              <ul>
                <li>
                  We believe in giving back to the communities we serve. Our
                  projects are designed not just to meet market demands but also
                  to contribute positively to the social and economic fabric of
                  the region.
                </li>
              </ul>
            </li>
            <li>
              Environmental Responsibility:
              <ul>
                <li>
                  Committed to environmental sustainability, we take proactive
                  measures to minimize the ecological impact of our projects.
                  From green spaces to energy-efficient design, we are dedicated
                  to preserving and enhancing the natural environment.
                </li>
              </ul>
            </li>
          </ol>
          <h5>Our Commitment to You</h5>
          <p>
            Choosing Helping Human Infratech Pvt. Ltd. means choosing a partner
            that is invested in your aspirations. Our mission and vision guide
            us in every decision and action we take, ensuring that our clients
            receive not just plots but a pathway to a better and brighter
            future.
          </p>
          <p>
            As we continue to grow and innovate, our commitment to providing
            exceptional service, fostering sustainable communities, and creating
            lasting value remains unwavering. Join us on this journey towards
            excellence, where your dreams find a home in the plots we offer.
          </p>
        </>
      ),
    },
    {
      uri: "patna-aero-city",
      featuredImage: "Patna Aero City",
      pageName: "Patna Aero City",
      pageHeader: "Patna Aero City",
      pageContent: (
        <>
          <p>On Bihta Sarmera NH 30, Only 1 KM away from Kanhauli Bus Stand</p>
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="aminities" title="Aminities">
              <ul>
                <li>Bihta Railway Station - 3 KM</li>
                <li>Danapur Railway Station - 12 KM</li>
                <li>International Airport - 3 KM</li>
                <li>Bihta to Patna 4 lane NH 30 - 20 meters</li>
                <li>Sarmera Bihta 6 Lane - 1 KM</li>
                <li>Metro Station 3rd phase - 4.5 KM</li>
              </ul>
              <img src={key_features_aerocity} />
            </Tab>
            <Tab eventKey="media" title="Media">
              <img src={aero_city_media} />
            </Tab>
            <Tab eventKey="payment-plan" title="Payment Plan"></Tab>
            <Tab eventKey="location-plan" title="Location Plan">
              <p></p>
            </Tab>
            <Tab eventKey="plot-size" title="Plot Size">
              <p></p>
            </Tab>
            <Tab eventKey="layout-plan" title="Layout Plan">
              <p></p>
            </Tab>
          </Tabs>
        </>
      ),
    },
    {
      uri: "ongoing-projects",
      featuredImage: "Ongoing Projects",
      pageName: "Ongoing Projects",
      pageHeader: "Ongoing Projects",
      pageContent: (
        <>
          <Projects />
        </>
      ),
    },
    {
      uri: "*",
      featuredImage: "404 Not found",
      pageName: "404 Not found",
      pageHeader: "404 Not found",
      pageContent: (
        <>
          <p>No associated description available</p>
        </>
      ),
    },
  ];

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<HomePageTemplate />} />
        {pages.map((val) => (
          <Route
            path={`/${val.uri}`}
            element={<OtherPageTemplate {...val} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
