import React from "react";
import Carousel from "react-bootstrap/Carousel";
import home1 from "../../Assets/home1.jpg";
import home2 from "../../Assets/home2.jpg";
import home3 from "../../Assets/home3.jpg";

const CustomCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          src={home1}
          alt="home 1"
          style={{ objectFit: "contain", width: "100%" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={home2}
          alt="home 2"
          style={{ objectFit: "contain", width: "100%" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={home3}
          alt="home 3"
          style={{ objectFit: "contain", width: "100%" }}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CustomCarousel;
