import React from "react";
import "./HomePageTemplate.scss";
import NavContainer from "../../Containers/NavContainer/NavContainer";
import FeaturedNav from "../../Containers/FeaturedNav/FeaturedNav";
import CustomCarousel from "../../Containers/Carousel/CustomCarousel";
import Featured from "../../Containers/Featured/Featured";
import Projects from "../../Containers/Projects/Projects";
import Testimonials from "../../Containers/Testimonials/Testimonials";
import Footer from "../../Containers/Footer/Footer";

const HomePageTemplate = () => {
  return (
    <>
      <FeaturedNav />
      <NavContainer />
      <CustomCarousel />
      <Featured />
      <Projects />
      <Testimonials />
      <Footer />
    </>
  );
};

export default HomePageTemplate;
