import React from "react";
import Card from "react-bootstrap/Card";
import aero_city_patna from "../../Assets/aero_city_patna.png";
import aay from "../../Assets/aay.png";
import maner from "../../Assets/maner.png";

const Projects = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>OUR PROJECTS</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "50px",
          flexWrap: "wrap",
        }}
      >
        <a
          href="/patna-aero-city"
          style={{ textDecoration: "none", margin: "10px" }}
        >
          <Card style={{ width: "18rem", minHeight: "12rem" }}>
            <Card.Img variant="top" src={aero_city_patna} />
            <Card.Body>
              <Card.Title>Patna Aero City</Card.Title>
              {/* <Card.Text>
                It is a project plan in Bihta which is also termed New Patna. It
                is a superb place to buy your land.
              </Card.Text> */}
            </Card.Body>
          </Card>
        </a>
        <a
          href="/maner-patna-project"
          style={{ textDecoration: "none", margin: "10px" }}
        >
          <Card style={{ width: "18rem", minHeight: "12rem" }}>
            <Card.Img variant="top" src={maner} />
            <Card.Body>
              <Card.Title>Maner Patna Project</Card.Title>
              {/* <Card.Text>
                It is project near Maner in Patna. It is one of the growing part
                of Patna.
              </Card.Text> */}
            </Card.Body>
          </Card>
        </a>
        <a
          href="/aam-admi-awas-yojna"
          style={{ textDecoration: "none", margin: "10px" }}
        >
          <Card style={{ width: "18rem", minHeight: "12rem" }}>
            <Card.Img variant="top" src={aay} />
            <Card.Body>
              <Card.Title>Aam Admin Awas Yojna</Card.Title>
              {/* <Card.Text>
                It is a scheme for every invidiual who is aspiring to have their
                own home or their own first home in Patna and nearby locality
              </Card.Text> */}
            </Card.Body>
          </Card>
        </a>
      </div>
    </div>
  );
};

export default Projects;
