import React from "react";
import "./TestimonialStrip.scss";

const TestimonialStrip = ({ quote, writer, source, writerImg }) => {
  return (
    <div style={{ margin: "30px" }}>
      <figure class="snip1157">
        <blockquote>
          {quote}
          <div class="arrow"></div>
        </blockquote>
        <img src={writerImg} alt="sq-sample3" />
        <div class="author">
          <h5>
            {writer}
            <span>{source}</span>
          </h5>
        </div>
      </figure>
    </div>
  );
};

export default TestimonialStrip;
