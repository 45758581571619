import React, { useState } from "react";
import house from "../../Assets/house.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Featured = () => {
  const [key, setKey] = useState("about");
  return (
    <Row
      style={{
        background: "rgb(228 228 1)",
        opacity: ".8",
        margin: "0",
        overflow: "auto",
      }}
      className="d-flex"
    >
      <Col sm={12} md={6}>
        <img
          src={house}
          alt="house"
          style={{
            width: "100%",
            margin: "0 80px 0 0 ",
            padding: "10px",
          }}
        />
      </Col>
      <Col
        style={{
          height: "400px",
          padding: "50px",
        }}
        sm={12}
        md={6}
      >
        <h3 style={{ color: "teal", marginBottom: "25px" }}>
          HELPING HUMAN: YOUR FIRST HOME LAND
        </h3>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="about" title="About Us">
            <p>
              Helping Human Infratech Pvt Ltd is all about making it easier for
              people to buy land and homes without breaking the bank. We focus
              on providing affordable options for townships and land. What makes
              us stand out is our dedication to simplifying the complicated
              legal stuff that usually comes with buying property. We want the
              whole process to be simple and stress-free for individuals.
            </p>
            <a href="/about-us">
              <Button>Read More</Button>
            </a>
          </Tab>
          <Tab eventKey="core" title="Our Mission">
            <p>
              We strive to provide our customers with the best prices, the best
              selection, and the most convenience.
            </p>
          </Tab>
          <Tab eventKey="realestate" title="Our Values">
            <p>
              <strong>Innovation:</strong> We embrace innovation as the
              heartbeat of our organization. We continually seek creative and
              sustainable solutions to redefine the real estate landscape. Our
              commitment to innovation drives us to explore new technologies,
              design concepts, and construction methodologies that elevate our
              projects to new heights.
            </p>

            <p>
              <strong>Integrity:</strong> Integrity is the cornerstone of our
              relationships—with our clients, partners, and the communities we
              operate in. We conduct our business with the highest ethical
              standards, transparency, and accountability. Building trust is not
              just a goal; it's an integral part of who we are.
            </p>
            <a href="/our-values">
              <Button>Read More</Button>
            </a>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Featured;
